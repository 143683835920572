import "./App.css";
import "../src/styles/style.css";
import "animate.css";

import Home from "./components/Home";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCRqy2oZU3LsM_qwOcSDDrHCVmH-qd_s_M",
  authDomain: "wunderheist-d85b4.firebaseapp.com",
  projectId: "wunderheist-d85b4",
  storageBucket: "wunderheist-d85b4.appspot.com",
  messagingSenderId: "908405884776",
  appId: "1:908405884776:web:de530abcdf5196ccc438dd",
  measurementId: "G-N3YKX55LG3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <Home/>
  );
}

export default App;
